// Adapted from https://github.com/pacocoursey/next-themes
// MIT LICENSE - pacocoursey

import { DISABLE_ANIMATION_CLASSNAME, IS_SERVER, MEDIA } from "./constants"

export const getThemeFromLocalstorage = (key: string, fallback?: string) => {
  if (IS_SERVER) return undefined
  let theme

  try {
    theme = localStorage.getItem(key) || undefined
  } catch (e) {
    // Do nothing
  }

  return theme || fallback
}

export const disableAnimation = () => {
  // use global style to avoid Content Security Policy Error
  document.documentElement.classList.add(DISABLE_ANIMATION_CLASSNAME)

  return () => {
    // Force restyle
    ;(() => window.getComputedStyle(document.body))()

    // Wait for next tick before removing
    setTimeout(() => {
      document.documentElement.classList.remove(DISABLE_ANIMATION_CLASSNAME)
    }, 1)
  }
}

export const getSystemTheme = (e?: MediaQueryList | MediaQueryListEvent) => {
  // eslint-disable-next-line no-param-reassign
  if (!e) e = window.matchMedia(MEDIA)
  const isDark = e.matches
  const systemTheme = isDark ? `dark` : `light`
  return systemTheme
}
