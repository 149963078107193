import "vanilla-cookieconsent/dist/css-components/base.css"
import "vanilla-cookieconsent/dist/css-components/consent-modal.css"
import "vanilla-cookieconsent/dist/css-components/preferences-modal.css"

import React from "react"

import type { GatsbyBrowser } from "gatsby"

import "./src/styles/fonts.css"
import "./src/styles/reset.css"
import "./src/styles/global.css"
import "./src/styles/cookie-consent.css"
import { Root } from "./src/components/blocks/root"
import { gtmConfig, trackGTMCustomEvent } from "./src/utils/analytics"

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => <Root element={element} />

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = () => {
  // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // to ensure the title has properly been changed
  // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
  setTimeout(() => {
    trackGTMCustomEvent({ event: gtmConfig.GtmRouteChangeEventName }, true)
  }, 50)
}
