export const trackGTMCustomEvent = (
  params: Record<string, unknown>,
  isPageView?: boolean,
) => {
  window?.trackEvent?.(params, isPageView)
}

export const shouldEnableAnalytics =
  process.env.ENABLE_ANALYTICS === `true` ||
  process.env.ENABLE_ANALYTICS === `1`

export const gtmConfig = {
  ContainerId: process.env.GTM_CONTAINER_ID,
  GtmCookieConsentUpdateEventName: `cookie-consent-update`,
  GtmCspReportEventName: `csp-report`,
  GtmRouteChangeEventName: `gatsby-route-change`,
}
