exports.components = {
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-content-404-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/content/404.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-content-404-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-content-clients-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/content/clients.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-content-clients-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-content-contact-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/content/contact.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-content-contact-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-content-cookie-policy-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/content/cookie-policy.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-content-cookie-policy-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-content-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/content/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-content-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-content-privacy-policy-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/content/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-content-privacy-policy-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-content-projects-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/content/projects.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-content-projects-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-content-services-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/content/services.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-content-services-mdx" */)
}

